import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import HistoryIcon from '@mui/icons-material/History'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, Button, CircularProgress, IconButton, Link, List, Typography } from '@mui/material'
import { FC, Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'
import { COLOR } from '../../App/constants/COLOR'
import { ENVIRONMENT } from '../../App/constants/ENVIRONMENT'
import { getTaskLogCollectionRef } from '../../Auth/helpers/getCollectionRef'
import { getTaskLogsFromSnapshot } from '../../Auth/helpers/getDataFromQuerySnapshot'
import { useHasPermission } from '../../Auth/hooks/useHasPermission'
import { FragmentWithBreaks } from '../../Common/components/FragmentWithBreaks'
import { ZAccordion } from '../../Common/components/ZAccordion'
import { ZDetailsListItem } from '../../Common/components/ZDetailsListItem'
import { getThirdPartyProviderTranslation } from '../../Common/helpers/getThirdPartyProviderTranslation'
import { LogListView } from '../../Log/components/LogListView'
import { TaskLogListItem } from '../../Log/components/TaskLogListItem'
import { useEstimatedDeliveryAt } from '../../Rider/hooks/useEstimatedDeliveryAt'
import { useRiderFromTask } from '../../Rider/hooks/useRiderFromTask'
import { useWarehouses } from '../../Warehouse/hooks/useWarehouses'
import { TASK_COMPLETE_STATUSES } from '../constants/TASK_COMPLETE_STATUSES'
import { getTaskCloneReasonTranslation } from '../helpers/getTaskCloneReasonTranslation'
import { getTaskDeliveryOptionTranslation } from '../helpers/getTaskDeliveryOptionTranslation'
import { getTaskFailureReasonTranslation } from '../helpers/getTaskFailureReasonTranslation'
import { getTaskPickingStatusTranslation } from '../helpers/getTaskPickingStatusTranslation'
import { getTaskStatusTranslation } from '../helpers/getTaskStatusTranslation'
import { hasTaskBeenPickedUp } from '../helpers/hasTaskBeenPickedUp'
import { useHoverTask } from '../hooks/useHoverTask'
import { useTaskDeliveryTimes } from '../hooks/useTaskDeliveryTimes'
import { useTaskFromTaskId } from '../hooks/useTaskFromTaskId'
import { TaskAssignRiderSelect } from './TaskAssignRiderSelect'
import { TaskAssignWarehouseSelect } from './TaskAssignWarehouseSelect'
import { TaskCloneDialog } from './TaskCloneDialog'
import { TaskCompleteDialog } from './TaskCompleteDialog'
import { TaskOnTimeView } from './TaskOnTimeView'
import { TaskPickingStatusSelect } from './TaskPickingStatusSelect'
import { TaskReturnStatusChangeDialog } from './TaskReturnStatusChangeDialog'
import { TaskStatusIcon } from './TaskStatusIcon'
import { TaskUpsertDialog } from './TaskUpsertDialog'

export const TaskDetailsView: FC = () => {
  const taskId = useParams<'taskId'>().taskId || 'unknown'
  const [task, isLoadingTask] = useTaskFromTaskId(taskId)
  const [stackedTask, isLoadingStackedTask] = useTaskFromTaskId(task?.stackedWithTaskIds?.[0])
  const warehouse = useWarehouses((state) => task && state.warehouses.find((w) => w.id === task.warehouseId))
  const hasPermission = useHasPermission()
  const rider = useRiderFromTask(task)
  const [isTaskCloneDialogOpen, setIsTaskCloneDialogOpen] = useState(false)
  const [isEditTaskDialogOpen, setIsEditTaskDialogOpen] = useState(false)
  const { onMouseOut, onMouseOver } = useHoverTask(task)
  const [isTaskCompleteDialogOpen, setIsTaskCompleteDialogOpen] = useState(false)
  const [isTaskReturnStatusChangeDialogOpen, setIsTaskReturnStatusChangeDialogOpen] = useState(false)
  const estimatedDeliveryAt = useEstimatedDeliveryAt({
    warehouse,
    rider,
    task,
  })
  const taskDeliveryTimes = useTaskDeliveryTimes(task, estimatedDeliveryAt)

  const isCompleted = task ? TASK_COMPLETE_STATUSES.includes(task.status) : true
  const isBlocked = task ? hasTaskBeenPickedUp(task.status) : true

  const isEditDisabled = !hasPermission('UPSERT_TASK') || isBlocked
  const isAssignRiderDisabled = !hasPermission('UPDATE_TASK_RIDER') || isBlocked
  const isAssignWarehouseDisabled = !hasPermission('UPDATE_TASK_WAREHOUSE') || isBlocked
  const isCompleteDisabled = !hasPermission('COMPLETE_TASK') || isCompleted

  const baseUrl = 'https://console.cloud.google.com/logs/query'
  const project = 'zapp-gb-app-zephyr-df70'
  let logUrl = ''
  if (task) {
    const startTime = new Date(task.createdAt.toMillis()).toISOString()
    const endTime = new Date(task.createdAt.toMillis() + 30 * 60 * 1000).toISOString()
    logUrl = `${baseUrl};query=${taskId};startTime=${startTime};endTime=${endTime}?referrer=search&project=${project}`
  }

  return (
    <>
      {!task && isLoadingTask && (
        <Box p={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!task && !isLoadingTask && (
        <Typography px={2} fontWeight={700} fontSize={21}>
          No task found.
        </Typography>
      )}
      {task && (
        <>
          <Box px={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography px={2} fontWeight={700} fontSize={21}>
              Task: {task.externalOrderNumber || task.id.substring(task.id.length - 8)}
            </Typography>
            {!task.completedAt && <TaskOnTimeView task={task} estimatedDeliveryAt={estimatedDeliveryAt} />}
          </Box>
          <Box p={1} display="flex" justifyContent="space-between" alignItems="center">
            <Button color="inherit" disabled>
              <Box display="flex" alignItems="center">
                <TaskStatusIcon task={task} riderStatus={rider?.status} />
                <Typography ml={1} fontWeight={500} color={COLOR.white[0]}>
                  {getTaskStatusTranslation(task)}
                </Typography>
              </Box>
            </Button>
            <IconButton color="inherit" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
              <VisibilityOutlinedIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
          <ZAccordion title="Details" titleIcon={<LocationOnIcon sx={{ color: COLOR.blue[50] }} />}>
            <List>
              {task.failureReason && (
                <ZDetailsListItem
                  title={`Failure: ${getTaskFailureReasonTranslation(task.failureReason)}`}
                  iconComponent={WarningAmberIcon}
                >
                  {!task.failureNotes && 'No notes'}
                  {task.failureNotes && <FragmentWithBreaks lines={task.failureNotes?.split('\n')} />}
                </ZDetailsListItem>
              )}
              {task.clonedFromTaskId && (
                <ZDetailsListItem
                  title="Cloned From"
                  iconComponent={ContentCopyIcon}
                  to={`/tasks/${task.clonedFromTaskId}`}
                >
                  <FragmentWithBreaks
                    lines={[
                      task.clonedFromTaskId.substring(task.clonedFromTaskId.length - 8),
                      task.cloneReason && `Clone Reason: ${getTaskCloneReasonTranslation(task.cloneReason)}`,
                    ]}
                  />
                </ZDetailsListItem>
              )}
              {!TASK_COMPLETE_STATUSES.includes(task.status) && (
                <ZDetailsListItem
                  title="Rider"
                  iconComponent={MopedOutlinedIcon}
                  to={task.riderId && `/tasks/${task.id}/riders/${task.riderId}`}
                  testid="TaskAssignedToListItem"
                  secondaryAction={<TaskAssignRiderSelect isDisabled={isAssignRiderDisabled} task={task} />}
                >
                  {rider ? (
                    <FragmentWithBreaks lines={[rider.name, rider.phone]} />
                  ) : task.riderId ? (
                    'Rider assigned'
                  ) : (
                    'Unassigned'
                  )}
                </ZDetailsListItem>
              )}
              <ZDetailsListItem
                title="Destination"
                iconComponent={LocationOnOutlinedIcon}
                testid="TaskDestinationListItem"
                onClick={isEditDisabled ? undefined : () => setIsEditTaskDialogOpen(true)}
              >
                <FragmentWithBreaks
                  lines={[
                    task.address.companyName,
                    task.address.streetAddress1,
                    task.address.streetAddress2,
                    [task.address.city, task.address.postalCode].filter((i) => i).join(' '),
                  ]}
                />
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Store"
                iconComponent={WarehouseOutlinedIcon}
                testid="TaskStoreListItem"
                secondaryAction={<TaskAssignWarehouseSelect isDisabled={isAssignWarehouseDisabled} task={task} />}
              >
                {warehouse?.name || 'Not set'}
              </ZDetailsListItem>
              <ZDetailsListItem title="Order" iconComponent={InfoOutlinedIcon} testid="TaskOrderNumberListItem">
                Number: <strong>{task.externalOrderNumber || 'Not set'}</strong>
                {task.externalOrderId && hasPermission('REDIRECT_TO_EXTERNAL_ORDER') && (
                  <>
                    <br />
                    <Link
                      href={`${ENVIRONMENT.zCommerceOrderUrl}${task.externalOrderId}`}
                      target="_blank"
                      color="inherit"
                    >
                      ZCommerce
                    </Link>
                    <br />
                    <Link
                      href={`${ENVIRONMENT.zappboardOrderUrl}?orderId=${task.externalOrderId}`}
                      target="_blank"
                      color="inherit"
                    >
                      Zappboard
                    </Link>
                    <br />
                    {task.thirdPartyProvider && task.thirdPartyProviderTrackingUrl && (
                      <Link href={task.thirdPartyProviderTrackingUrl} target="_blank" color="inherit">
                        {getThirdPartyProviderTranslation(task.thirdPartyProvider)}
                      </Link>
                    )}
                  </>
                )}
              </ZDetailsListItem>
              <ZDetailsListItem
                title="Picking Status"
                iconComponent={ShoppingBasketOutlinedIcon}
                secondaryAction={<TaskPickingStatusSelect task={task} />}
              >
                {getTaskPickingStatusTranslation(task.pickingStatus)}
              </ZDetailsListItem>
              {stackedTask && !isLoadingStackedTask && (
                <ZDetailsListItem title="Stacked With" iconComponent={LayersOutlinedIcon}>
                  Number: <strong>{stackedTask.externalOrderNumber || 'Not set'}</strong>
                  {stackedTask.externalOrderId && hasPermission('REDIRECT_TO_EXTERNAL_ORDER') && (
                    <>
                      <br />
                      <Link
                        href={`${ENVIRONMENT.zCommerceOrderUrl}${stackedTask.externalOrderId}`}
                        target="_blank"
                        color="inherit"
                      >
                        ZCommerce
                      </Link>
                      <br />
                      <Link
                        href={`${ENVIRONMENT.zappboardOrderUrl}?orderId=${stackedTask.externalOrderId}`}
                        target="_blank"
                        color="inherit"
                      >
                        Zappboard
                      </Link>
                      <br />
                      {stackedTask.thirdPartyProvider && stackedTask.thirdPartyProviderTrackingUrl && (
                        <Link href={stackedTask.thirdPartyProviderTrackingUrl} target="_blank" color="inherit">
                          {getThirdPartyProviderTranslation(stackedTask.thirdPartyProvider)}
                        </Link>
                      )}
                    </>
                  )}
                </ZDetailsListItem>
              )}
              <ZDetailsListItem
                title="Delivery Notes"
                iconComponent={NoteAltOutlinedIcon}
                testid="TaskDeliveryNotesListItem"
              >
                Instructions: <strong>{getTaskDeliveryOptionTranslation(task.address.deliveryOption)}</strong>
                <br />
                Notes: <strong>{task.noteFromCustomer || 'Not set'}</strong>
                <br />
                Customer Service: <strong>{task.customer.noteFromCustomerService || 'Not set'}</strong>
              </ZDetailsListItem>
              <ZDetailsListItem title="Delivery" iconComponent={AccessTimeIcon} testid="EstimatedDeliveryListItem">
                {taskDeliveryTimes.map((deliveryTime, index) => (
                  <Fragment key={deliveryTime.translation}>
                    {deliveryTime.translation}: <strong>{deliveryTime.dateTime.toFormat('HH:mm')}</strong>
                    {index < taskDeliveryTimes.length - 1 && <br />}
                  </Fragment>
                ))}
              </ZDetailsListItem>
              {logUrl && (
                <ZDetailsListItem title="Logs for Devs" iconComponent={LibraryBooksIcon} testid="logsForDevs">
                  <Link href={logUrl} target="_blank" color="inherit">
                    View Logs
                  </Link>
                </ZDetailsListItem>
              )}
            </List>
          </ZAccordion>
          <ZAccordion title="Timeline" titleIcon={<HistoryIcon sx={{ color: COLOR.blue[50] }} />}>
            <LogListView
              collectionRef={getTaskLogCollectionRef(taskId)}
              getLogsFromSnapshot={getTaskLogsFromSnapshot}
              LogListItem={TaskLogListItem}
              limit={100}
            />
          </ZAccordion>
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            {task.status !== 'NOT_RETURNED' ? (
              <Button
                color="success"
                disabled={isCompleteDisabled}
                onClick={() => setIsTaskCompleteDialogOpen(true)}
                data-testid="CompleteTaskButton"
              >
                Complete
              </Button>
            ) : (
              <Button
                color="success"
                onClick={() => setIsTaskReturnStatusChangeDialogOpen(true)}
                data-testid="ChangeTaskStatusButton"
              >
                Change Status
              </Button>
            )}
            <Button
              color="warning"
              disabled={!hasPermission('CLONE_TASK')}
              onClick={() => setIsTaskCloneDialogOpen(true)}
              data-testid="CloneTaskButton"
            >
              Clone
            </Button>
            <Button
              disabled={isEditDisabled}
              onClick={() => setIsEditTaskDialogOpen(true)}
              data-testid="EditTaskButton"
            >
              Edit
            </Button>
          </Box>
          <TaskCompleteDialog
            task={task}
            isOpen={isTaskCompleteDialogOpen}
            close={() => setIsTaskCompleteDialogOpen(false)}
          />
          <TaskCloneDialog task={task} isOpen={isTaskCloneDialogOpen} close={() => setIsTaskCloneDialogOpen(false)} />
          <TaskUpsertDialog task={task} isOpen={isEditTaskDialogOpen} close={() => setIsEditTaskDialogOpen(false)} />
          <TaskReturnStatusChangeDialog
            task={task}
            isOpen={isTaskReturnStatusChangeDialogOpen}
            close={() => setIsTaskReturnStatusChangeDialogOpen(false)}
          />
        </>
      )}
    </>
  )
}
